import PieChart from "@/components/chart/PieChart";
import ScoreChart from "@/components/chart/ScoreChart";
import BatterTableDataView from "@/components/BatterTableDataView";
import api from "@/api";
export default {
  name: "ClassRoomAnswerDetailView",
  components: {
    BatterTableDataView,
    ScoreChart,
    PieChart
  },
  data() {
    return {
      roomId: this.$route.params.roomId,
      tplId: this.$route.params.tplId,
      state: this.$route.params.state,
      statistic: {
        state: null,
        accuracy: 0,
        score: 0
      }
    };
  },
  methods: {
    review(session) {
      window.open(`/#/train/${session.subject}/${session.examId}`);
      // this.$router.push()
    },
    init() {
      this.$refs.userAnswerTable.fetchData();
      api.getClassAnswerStatistic({
        'roomId': this.roomId,
        'tplId': this.tplId,
        'state': this.state
      }).then(ret => {
        this.statistic.state = ret.statistic;
        this.statistic.accuracy = ret.accuracy;
        this.statistic.score = ret.score;
      });
    }
  },
  mounted() {
    this.init();
  }
};