import "core-js/modules/es.array.push.js";
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-0a5de904"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "container"
};
const _hoisted_2 = {
  class: "box"
};
const _hoisted_3 = {
  key: 0,
  class: "text-center text-3xl text-red-500"
};
const _hoisted_4 = {
  key: 1,
  class: "number"
};
const _hoisted_5 = {
  key: 2,
  class: "text-center text-3xl"
};
const _hoisted_6 = {
  class: "btns min-h-[60px]"
};
import { onUnmounted, ref, watch } from 'vue';
export default {
  __name: 'MemoryNumbers',
  props: {
    items: {
      type: Array,
      default: () => [0, 1, 0, 3, 4, 3, 2, 1, 0, 1]
    }
  },
  emits: ['answer'],
  setup(__props, {
    emit: __emit
  }) {
    const emit = __emit;
    const props = __props;
    const index = ref(-1);
    const status = ref(-1);
    let timer;
    const answer = ref([]);
    const start = () => {
      next();
    };
    watch(props, newVal => {
      answer.value = [];
      index.value = -1;
      status.value = -1;
      clearInterval(timer);
    });
    const next = () => {
      if (index.value >= props.items.length - 1) {
        status.value = 3;
        emit('answer', answer.value);
        return;
      }
      status.value = 0;
      timer = setTimeout(() => {
        status.value = 1;
        index.value += 1;
        timer = setTimeout(() => {
          status.value = 2;
          if (index.value < 2) {
            next();
          }
        }, 3000);
      }, 500);
    };
    onUnmounted(() => {
      clearInterval(timer);
    });
    const onChoosed = val => {
      answer.value.push(val);
      clearInterval(timer);
      next();
    };
    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [status.value === 0 ? (_openBlock(), _createElementBlock("div", _hoisted_3, " + ")) : status.value === 1 ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(props.items[index.value]), 1)) : status.value === 3 ? (_openBlock(), _createElementBlock("div", _hoisted_5, " 完成 ")) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_6, [status.value === -1 ? (_openBlock(), _createBlock(_component_el_button, {
        key: 0,
        type: "primary",
        onClick: start
      }, {
        default: _withCtx(() => [_createTextVNode("开始")]),
        _: 1
      })) : _createCommentVNode("", true), index.value > 1 && index.value < props.items.length && [1, 2].includes(status.value) ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 1
      }, [_createVNode(_component_el_button, {
        onClick: _cache[0] || (_cache[0] = $event => onChoosed('Y'))
      }, {
        default: _withCtx(() => [_createTextVNode("相同")]),
        _: 1
      }), _createVNode(_component_el_button, {
        onClick: _cache[1] || (_cache[1] = $event => onChoosed('N'))
      }, {
        default: _withCtx(() => [_createTextVNode("不同")]),
        _: 1
      })], 64)) : _createCommentVNode("", true)])]);
    };
  }
};