import "core-js/modules/es.array.push.js";
import MemoryNumbers from "./MemoryNumbers.vue";
export default {
  name: "MemoryNumbersRender",
  props: {
    difficulty: {
      type: Object,
      default: () => {
        return {
          playInterval: 1
        };
      }
    },
    review: {
      type: Object,
      default: () => {
        return null;
      }
    },
    reviewMode: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => {
        return null;
      }
    }
  },
  watch: {
    data() {
      this.init();
    }
  },
  data() {
    return {
      answer: [],
      answerState: 0,
      alertVisible: false
    };
  },
  methods: {
    next() {
      this.$emit('next');
    },
    onClickCard(cardIndex) {
      if (this.answer.length >= this.data.rightAnswers.length) {
        return;
      }
      this.answer.push(cardIndex);
      let right = this.data.rightAnswers[this.answer.length - 1];
      this.$refs['hamster' + right][0].play(this.difficulty.playInterval * 1000);
      if (right == cardIndex) {
        this.answerState = 1;
      } else {
        this.answerState = 2;
      }
      setTimeout(() => {
        this.answerState = 0;
        if (this.answer.length >= this.data.rightAnswers.length) {
          this.alertVisible = true;
          this.$emit('answer', this.answer.join(','));
        }
      }, this.difficulty.playInterval * 1000);
    },
    init() {
      this.answer = [];
      this.alertVisible = false;
    },
    onAnswer(answer) {
      this.$emit('answer', answer.join(','));
    }
  },
  mounted() {
    this.init();
  }
};