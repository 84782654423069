import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.map.js";
import api from "@/api";
export default {
  name: "MyClassRoomView",
  data() {
    return {
      roomId: null,
      tpls: [],
      subjectMap: {},
      task: null
    };
  },
  methods: {
    toExam(tpl) {
      api.startTplWork({
        roomId: this.roomId,
        'tplId': tpl.id,
        state: 'ON'
      }).then(ret => {
        this.$router.push(`/train/${ret.subject}/${ret.examId}`);
      });
    },
    queryRoom() {
      api.queryClassRoom().then(ret => {
        if (ret != null) {
          this.roomId = ret;
          this.getActiveTplList();
        }
      });
    },
    getActiveTplList() {
      api.getActiveTplList(this.roomId).then(ret => {
        this.tpls = ret.tplList;
        this.subjectMap = ret.map;
      });
    },
    init() {
      this.queryRoom();
      this.task = setInterval(this.queryRoom, 3000);
    }
  },
  mounted() {
    this.init();
  },
  unmounted() {
    try {
      clearInterval(this.task);
    } catch (e) {
      console.log(e);
    }
  }
};