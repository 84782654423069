import "core-js/modules/esnext.iterator.map.js";
import { withAsyncContext as _withAsyncContext } from 'vue';
import { createVNode as _createVNode, resolveComponent as _resolveComponent, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-42f508a1"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "box"
};
const _hoisted_2 = {
  key: 0
};
import OSS from 'ali-oss';
import api from "@/api";
import base64ImgtoFile from '@/utils/tools';
import { ElMessage } from "element-plus";
import { ref, onMounted } from 'vue';
import { TresCanvas, useLoader } from '@tresjs/core';
import { OrbitControls } from '@tresjs/cientos';
import { BasicShadowMap, NoToneMapping, SRGBColorSpace } from 'three';
import { TextureLoader } from "three";
import { BoxGeometry } from "three";
import { Mesh } from "three";
import { MeshToonMaterial } from "three";
import ScreenshotView from './ScreenshotView.vue';
const path = '/dice-textures';
const clearColor = '#d5d7d2';
export default {
  __name: 'DiceItem',
  props: {
    size: {
      type: Object,
      default: () => {
        return {
          width: 200,
          height: 200
        };
      }
    },
    cubeIcons: {
      type: Array,
      default: () => {
        return [0, 1, 2];
      }
    },
    previewImages: {
      type: Object,
      default: () => {
        return {};
      }
    },
    localIndex: {
      type: Number,
      default: 0
    },
    isSave: {
      type: Boolean,
      default: true
    },
    isDesc: {
      type: Boolean,
      default: false
    }
  },
  emits: ['on-save-image'],
  async setup(__props, {
    emit: __emit
  }) {
    let __temp, __restore;
    const props = __props;
    const ossInitState = ref(false);
    const ossClient = ref(null);
    const ossDomain = ref(null);
    const ossFolder = ref(null);
    const screenshotRef = ref(null);
    const iconNames = ['eye', 'moon', 'pause', 'quote', 'suitcase', 'tag'];
    const textures = ([__temp, __restore] = _withAsyncContext(() => Promise.all(iconNames.map(name => useLoader(TextureLoader, path + `/${name}.png`)))), __temp = await __temp, __restore(), __temp);
    const textureMap = iconNames.reduce((acc, name, index) => {
      acc[name] = textures[index];
      return acc;
    }, {});
    const colors = ['#7bd074', '#cd7ac8', '#a5ada5'];
    const gl = {
      clearColor,
      shadows: true,
      alpha: false,
      shadowMapType: BasicShadowMap,
      outputColorSpace: SRGBColorSpace,
      toneMapping: NoToneMapping
    };
    const positions = [[0, 0, 1], [-0.866, 0, -0.5], [0.866, 0, -0.5]];
    const geometry = new BoxGeometry(1, 1, 1);
    const boxes = colors.map((color, index) => {
      const upIcon = iconNames[props.cubeIcons[index]];
      const textureIcons = iconNames.filter(name => !upIcon.includes(name)).sort(() => .5 - Math.random());
      textureIcons.splice(2, 0, upIcon);
      const mesh = new Mesh(geometry, textureIcons.map(x => new MeshToonMaterial({
        color,
        transparent: true,
        map: textureMap[x],
        onBeforeCompile: shader => {
          shader.fragmentShader = shader.fragmentShader.replace("#include <color_fragment>", `#include <color_fragment>
  if ( diffuseColor.a > 0.5 ) {
      diffuseColor = vec4(vec3(1.0,1.0,1.0), 1.0);
  } else {
      diffuseColor = vec4(diffuse, 1.0);
  }`);
        }
      })));
      mesh.position.set(...positions[index]);
      mesh.rotation.y = Math.random() * Math.PI * 2;
      mesh.castShadow = true;
      return mesh;
    });
    const emit = __emit;
    onMounted(() => {
      initOssClint();
    });
    const guid = () => {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0,
          v = c === 'x' ? r : r & 0x3 | 0x8;
        return v.toString(16);
      });
    };
    const initOssClint = () => {
      if (ossInitState.value) {
        console.log('OSS已经完成初始化!');
        return;
      }
      ossInitState.value = true;
      api.getOssKeySecret().then(cfg => {
        ossClient.value = new OSS({
          region: cfg.region,
          accessKeyId: cfg.accessKeyId,
          accessKeySecret: cfg.accessKeySecret,
          stsToken: cfg.securityToken,
          bucket: cfg.bucket,
          endpoint: cfg.endpoint,
          secure: true
        });
        ossDomain.value = cfg.domain;
        ossFolder.value = cfg.folder;
      });
    };
    const handleSaveImage = () => {
      screenshotRef.value.takeScreenshot();
    };
    const handleTakeScreenshot = imgData => {
      const img = base64ImgtoFile(imgData, new Date().getTime());
      const fileName = (ossFolder.value ? ossFolder.value + '/' : 'user-upload/') + guid() + '.png';
      ossClient.value.put(fileName, img).then(res => {
        if (res.res.statusCode === 200) {
          ElMessage.success('上传成功');
          const imgUrl = `https://sjxzc.oss-cn-beijing.aliyuncs.com/${fileName}`;
          emit('onSaveImage', imgUrl, props.localIndex, props.isDesc);
        } else {
          ElMessage.info('上传失败');
        }
      });
    };
    return (_ctx, _cache) => {
      const _component_TresPerspectiveCamera = _resolveComponent("TresPerspectiveCamera");
      const _component_primitive = _resolveComponent("primitive");
      const _component_TresGroup = _resolveComponent("TresGroup");
      const _component_TresPlaneGeometry = _resolveComponent("TresPlaneGeometry");
      const _component_TresMeshToonMaterial = _resolveComponent("TresMeshToonMaterial");
      const _component_TresMesh = _resolveComponent("TresMesh");
      const _component_TresAmbientLight = _resolveComponent("TresAmbientLight");
      const _component_TresDirectionalLight = _resolveComponent("TresDirectionalLight");
      const _component_el_tag = _resolveComponent("el-tag");
      const _component_el_button = _resolveComponent("el-button");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
        class: "cube",
        style: _normalizeStyle({
          height: `${__props.size.height}px`,
          width: `${__props.size.width}px`
        })
      }, [_createVNode(_unref(TresCanvas), _normalizeProps(_guardReactiveProps(gl)), {
        default: _withCtx(() => [_createVNode(ScreenshotView, {
          ref_key: "screenshotRef",
          ref: screenshotRef,
          onOnTakeScreenshot: handleTakeScreenshot
        }, null, 512), _createVNode(_component_TresPerspectiveCamera, {
          position: [3, 5, 3]
        }), _createVNode(_unref(OrbitControls)), _createVNode(_component_TresGroup, {
          "rotate-y": Math.PI * Math.random()
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(boxes), (box, index) => {
            return _openBlock(), _createBlock(_component_primitive, {
              key: index,
              object: box
            }, null, 8, ["object"]);
          }), 128))]),
          _: 1
        }, 8, ["rotate-y"]), _createVNode(_component_TresMesh, {
          "receive-shadow": "",
          "rotate-x": -Math.PI / 2,
          "position-y": "-0.5"
        }, {
          default: _withCtx(() => [_createVNode(_component_TresPlaneGeometry, {
            args: [100, 100]
          }), _createVNode(_component_TresMeshToonMaterial, {
            color: clearColor
          })]),
          _: 1
        }, 8, ["rotate-x"]), _createVNode(_component_TresAmbientLight, {
          intensity: 1
        }), _createVNode(_component_TresDirectionalLight, {
          position: [0, 60, -40],
          intensity: 2,
          "cast-shadow": ""
        })]),
        _: 1
      }, 16)], 4), __props.isSave ? (_openBlock(), _createElementBlock("div", _hoisted_2, [(__props.isDesc ? __props.previewImages?.question : __props.previewImages?.answers?.[__props.localIndex]) ? (_openBlock(), _createBlock(_component_el_tag, {
        key: 0,
        style: {
          "margin-right": "10px"
        },
        type: "success"
      }, {
        default: _withCtx(() => [_createTextVNode(" 已上传 ")]),
        _: 1
      })) : _createCommentVNode("", true), _createVNode(_component_el_button, {
        size: "small",
        disabled: !ossClient.value,
        type: "primary",
        onClick: handleSaveImage
      }, {
        default: _withCtx(() => [_createTextVNode(" 保存图片 ")]),
        _: 1
      }, 8, ["disabled"])])) : _createCommentVNode("", true)]);
    };
  }
};