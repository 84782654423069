export default {
  name: "ReviewAnswerCard",
  props: {
    questions: {
      type: Array,
      default: () => {
        return []; //[0,1,2,]
      }
    },
    costTime: {
      type: Number,
      default: 0
    },
    groupSize: {
      type: Number,
      default: 1
    }
  },
  watch: {
    questions() {
      this.init();
    }
  },
  data() {
    return {};
  },
  methods: {
    secondsToMMSS(seconds) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      const formattedMinutes = String(minutes).padStart(2, '0');
      const formattedSeconds = String(remainingSeconds).padStart(2, '0');
      return `${formattedMinutes}:${formattedSeconds}`;
    },
    init() {}
  },
  mounted() {
    this.init();
  },
  unmounted() {}
};