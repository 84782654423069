import * as echarts from 'echarts';
export default {
  name: "PieChart",
  props: {
    data: {
      type: Object,
      default: () => {
        return {
          notStart: 10,
          complete: 10,
          testing: 10
        };
      }
    }
  },
  watch: {
    data() {
      this.render();
    }
  },
  methods: {
    render() {
      let option = {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'horizontal',
          left: 'center',
          bottom: 'bottom'
        },
        grid: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0
        },
        series: [{
          type: 'pie',
          radius: '70%',
          data: [{
            value: this.data.testing,
            name: '答题中',
            itemStyle: {
              color: '#5996FF'
            }
          }, {
            value: this.data.complete,
            name: '已完成',
            itemStyle: {
              color: '#07D3A5'
            }
          }, {
            value: this.data.notStart,
            name: '未开始',
            itemStyle: {
              color: '#F56C6C'
            }
          }],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          },
          label: {
            show: true,
            // 显示标签
            position: 'inside',
            formatter: '{d}%',
            textStyle: {
              color: 'white'
            }
          },
          labelLine: {
            show: false // 设置为 false 关闭指示线
          }
        }]
      };
      let pie = document.getElementById('pieChart');
      let exist = echarts.getInstanceByDom(pie);
      if (exist) {
        console.log(this.id, '销毁已存在的');
        exist.dispose();
      }
      let chart = echarts.init(pie);
      chart.setOption(option);
    },
    init() {
      this.render();
    }
  },
  mounted() {
    this.init();
  }
};