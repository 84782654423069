import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-662b6336"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "container gap-4"
};
const _hoisted_2 = {
  class: "content flex-1 bg-gray-50 z-10 p-4 border-box border rounded-2xl"
};
const _hoisted_3 = {
  class: "absolute p-4"
};
const _hoisted_4 = {
  class: "render-container gap-4"
};
const _hoisted_5 = {
  class: "flex flex-wrap gap-4 w-[400px] items-center justify-center"
};
const _hoisted_6 = ["onClick"];
const _hoisted_7 = {
  class: "w-[90px] h-[90px] rounded-xl overflow-hidden"
};
const _hoisted_8 = ["src"];
import * as THREE from 'three';
import { Mesh, MeshStandardMaterial, Vector3 } from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls.js';
import { onMounted, ref, watch } from "vue";
import { OBJLoader } from 'three/addons/loaders/OBJLoader.js';
import * as BufferGeometryUtils from "three/examples/jsm/utils/BufferGeometryUtils.js";
import { models } from '@/models.js';
export default {
  __name: 'CSGItem',
  props: {
    previewImages: {
      type: Object,
      default: () => {
        return {};
      }
    },
    localIndex: {
      type: Number,
      default: 0
    },
    isSave: {
      type: Boolean,
      default: true
    },
    isDesc: {
      type: Boolean,
      default: false
    },
    size: {
      type: Object,
      default: () => {
        return {
          width: 200,
          height: 200
        };
      }
    }
  },
  setup(__props) {
    console.log(BufferGeometryUtils);
    const scene = new THREE.Scene();
    const mergeGeometries = model => {
      const geometries = [];
      model.traverse(child => {
        if (child instanceof Mesh) {
          const geometry = child.geometry.clone();
          geometry.applyMatrix4(child.matrixWorld);
          geometries.push(geometry);
        }
      });
      return BufferGeometryUtils.mergeGeometries(geometries);
    };
    const normalizeGeometry = (geometry, bottom) => {
      if (!geometry.boundingBox) {
        geometry.computeBoundingBox();
      }
      const bbox = geometry.boundingBox;
      if (!bbox) return geometry;
      const size = bbox.getSize(new Vector3());
      const center = bbox.getCenter(new Vector3());
      const maxDimension = Math.max(size.x, size.y, size.z);
      const scale = 1 / maxDimension;
      geometry.scale(scale, scale, scale);
      center.multiplyScalar(scale);
      geometry.translate(-center.x, bottom ? bbox.min.y : -center.y, -center.z);
      return geometry;
    };
    let transformControls;
    const rendererRef = ref(null);
    const loading = ref(false);
    const mode = ref('translate');
    const selectedIndex = ref(0);
    const controlObject = new THREE.Object3D();
    const planeHelper = new THREE.PlaneHelper(new THREE.Plane(), 1, 0xff0000);
    const geometry = new THREE.BoxGeometry();
    const baseMat = new THREE.MeshBasicMaterial();
    baseMat.depthWrite = false;
    baseMat.depthTest = false;
    baseMat.colorWrite = false;
    baseMat.stencilWrite = true;
    baseMat.stencilFunc = THREE.AlwaysStencilFunc;

    // back faces
    const mat0 = baseMat.clone();
    mat0.side = THREE.BackSide;
    //  mat0.clippingPlanes = [ plane ];
    mat0.stencilFail = THREE.IncrementWrapStencilOp;
    mat0.stencilZFail = THREE.IncrementWrapStencilOp;
    mat0.stencilZPass = THREE.IncrementWrapStencilOp;
    const backMesh = new THREE.Mesh(geometry, mat0);
    backMesh.renderOrder = 1;

    // front faces
    const mat1 = baseMat.clone();
    mat1.side = THREE.FrontSide;
    //  mat1.clippingPlanes = [ plane ];
    mat1.stencilFail = THREE.DecrementWrapStencilOp;
    mat1.stencilZFail = THREE.DecrementWrapStencilOp;
    mat1.stencilZPass = THREE.DecrementWrapStencilOp;
    const frontMesh = new THREE.Mesh(geometry, mat1);
    frontMesh.renderOrder = 1;
    const planeMesh = new THREE.Mesh(new THREE.PlaneGeometry(4, 4), new THREE.MeshStandardMaterial({
      color: 0xE91E63,
      metalness: 0.1,
      roughness: 0.75,
      stencilWrite: true,
      stencilRef: 0,
      stencilFunc: THREE.NotEqualStencilFunc,
      stencilFail: THREE.ReplaceStencilOp,
      stencilZFail: THREE.ReplaceStencilOp,
      stencilZPass: THREE.ReplaceStencilOp
    }));
    planeMesh.rotation.x = -Math.PI / 2;
    planeMesh.rotation.y = Math.PI / 2;
    planeMesh.onAfterRender = function (renderer) {
      renderer.clearStencil();
    };
    planeMesh.renderOrder = 1.1;
    const model = new Mesh(geometry, new MeshStandardMaterial({
      color: 0xf0f0f0,
      clipShadows: true
    }));
    model.renderOrder = 6;
    model.castShadow = true;
    model.receiveShadow = true;
    const loadModel = () => {
      const item = models[selectedIndex.value];
      if (!item) return;
      const loader = new OBJLoader();
      loading.value = true;
      loader.load(`https://cdn.zhixuein.com/models/new/${item}.obj`, function (object) {
        const geometry = normalizeGeometry(mergeGeometries(object));
        model.geometry = geometry;
        backMesh.geometry = geometry;
        frontMesh.geometry = geometry;
        loading.value = false;
      });
    };
    watch(mode, val => {
      transformControls.setMode(val);
    });
    watch(selectedIndex, () => {
      loadModel();
    });
    const props = __props;
    const handleSelect = index => {
      if (loading.value) return;
      selectedIndex.value = index;
    };
    const updatePlane = () => {
      const plane = new THREE.Plane();
      controlObject.updateMatrixWorld();
      plane.applyMatrix4(controlObject.matrixWorld).negate();
      const plane2 = plane.clone();
      plane2.constant += 0.001;
      planeHelper.plane = plane;
      model.material.clippingPlanes = [plane];
      backMesh.material.clippingPlanes = [plane2];
      frontMesh.material.clippingPlanes = [plane2];
      planeMesh.material.clippingPlanes = [plane2];
    };
    const initThreeJS = () => {
      const container = rendererRef.value;
      const width = container.clientWidth;
      const height = container.clientHeight;
      const clearColor = 0xf0f0ff;

      // 场景
      scene.background = new THREE.Color(0xffffff);
      scene.fog = new THREE.Fog(clearColor, 10, 15);
      scene.background = new THREE.Color(clearColor);

      // 相机
      const camera = new THREE.OrthographicCamera(-1, 1, 1, -1, 1, 10);
      camera.position.set(1, 1.5, -1.5);
      camera.lookAt(0, 0, 0);

      // 渲染器
      const renderer = new THREE.WebGLRenderer({
        antialias: true
      });
      renderer.setSize(width, height);
      renderer.shadowMap.enabled = true; // 启用阴影映射
      renderer.localClippingEnabled = true;
      rendererRef.value.appendChild(renderer.domElement);

      // 环境光
      const ambientLight = new THREE.AmbientLight(0x606060, 20); // 软白光
      scene.add(ambientLight);

      // 平行光
      const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
      directionalLight.position.set(10, 10, 5);
      directionalLight.castShadow = true;
      directionalLight.shadow.mapSize.width = 512; // default
      directionalLight.shadow.mapSize.height = 512; // default
      directionalLight.shadow.camera.near = 0.5; // default
      directionalLight.shadow.camera.far = 500; // default
      scene.add(directionalLight);

      // 控制器
      const controls = new OrbitControls(camera, renderer.domElement);
      controls.enableDamping = true;
      controls.dampingFactor = 0.1;
      controls.enableZoom = true;

      // 变换控制器
      transformControls = new TransformControls(camera, renderer.domElement);
      transformControls.addEventListener('dragging-changed', e => {
        controls.enabled = !e.value;
      });
      transformControls.addEventListener('objectChange', () => {
        updatePlane();
      });
      scene.add(transformControls);

      // 添加地板
      const floor = new THREE.Mesh(new THREE.PlaneGeometry(100, 100), new THREE.MeshStandardMaterial({
        color: 0xf0f0ff
      }));
      floor.rotation.x = -Math.PI / 2;
      floor.position.y = -0.5;
      floor.receiveShadow = true;
      scene.add(floor);
      loadModel();
      controlObject.add(planeMesh);
      controlObject.rotation.z = Math.PI / 4;
      transformControls.attach(controlObject);
      scene.add(controlObject);
      scene.add(model);
      scene.add(backMesh);
      scene.add(frontMesh);
      scene.add(planeHelper);
      updatePlane();

      // 动画循环
      const animate = () => {
        requestAnimationFrame(animate);
        controls.update();
        renderer.render(scene, camera);
      };
      animate();
    };
    onMounted(() => {
      initThreeJS();
    });
    return (_ctx, _cache) => {
      const _component_Rank = _resolveComponent("Rank");
      const _component_el_icon = _resolveComponent("el-icon");
      const _component_el_radio_button = _resolveComponent("el-radio-button");
      const _component_Refresh = _resolveComponent("Refresh");
      const _component_el_radio_group = _resolveComponent("el-radio-group");
      const _directive_loading = _resolveDirective("loading");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_el_radio_group, {
        modelValue: mode.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => mode.value = $event)
      }, {
        default: _withCtx(() => [_createVNode(_component_el_radio_button, {
          label: "translate"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_icon, null, {
            default: _withCtx(() => [_createVNode(_component_Rank)]),
            _: 1
          })]),
          _: 1
        }), _createVNode(_component_el_radio_button, {
          label: "rotate"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_icon, null, {
            default: _withCtx(() => [_createVNode(_component_Refresh)]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      }, 8, ["modelValue"])]), _createElementVNode("div", _hoisted_4, [_withDirectives(_createElementVNode("div", {
        ref_key: "rendererRef",
        ref: rendererRef,
        class: "flex-1 border aspect-square rounded-xl overflow-hidden bg-white"
      }, null, 512), [[_directive_loading, loading.value]])])]), _createElementVNode("div", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(models), (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          key: item,
          class: _normalizeClass(["render-item", [selectedIndex.value === index && 'selected', loading.value && 'loading', 'w-[80px] h-[80px]']]),
          onClick: $event => handleSelect(index)
        }, [_createElementVNode("div", _hoisted_7, [_createElementVNode("img", {
          src: `https://cdn.zhixuein.com/models/new/${item}.png`,
          class: "w-full h-full object-cover"
        }, null, 8, _hoisted_8)])], 10, _hoisted_6);
      }), 128))])]);
    };
  }
};