import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "border-b border-gray-300 pb-2 flex justify-between items-center"
};
const _hoisted_2 = {
  class: "flex flex-wrap gap-2 mt-2"
};
const _hoisted_3 = ["src"];
import OssUploader from '@/components/OssUploader.vue';
import axios from 'axios';
import { onMounted, ref } from 'vue';
export default {
  __name: 'IconSelector',
  emits: ['select'],
  setup(__props, {
    emit: __emit
  }) {
    const icons = ref([]);
    const emit = __emit;
    const handleUploadSuccess = ({
      url,
      client
    }) => {
      const list = [url, ...icons.value];
      const content = JSON.stringify(list);
      const file = new Blob([content], {
        type: 'application/json'
      });
      client.put('/icons.json', file);
      emit('select', url);
    };
    onMounted(async () => {
      const res = await axios({
        url: 'https://sjxzc.oss-cn-beijing.aliyuncs.com/icons.json'
      });
      icons.value = res.data;
    });
    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");
      return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createTextVNode(" 请选择下方已经存在的图案，或点击上传新图案。 "), _createVNode(OssUploader, {
        onOnUploadedWithClient: handleUploadSuccess
      }, {
        default: _withCtx(() => [_createVNode(_component_el_button, {
          type: "primary"
        }, {
          default: _withCtx(() => [_createTextVNode("上传")]),
          _: 1
        })]),
        _: 1
      })]), _createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(icons.value, icon => {
        return _openBlock(), _createElementBlock("div", {
          key: icon
        }, [_createVNode(_component_el_button, {
          onClick: $event => emit('select', icon)
        }, {
          default: _withCtx(() => [_createElementVNode("img", {
            src: icon,
            alt: "icon",
            class: "w-5 h-5 object-contain"
          }, null, 8, _hoisted_3)]),
          _: 2
        }, 1032, ["onClick"])]);
      }), 128))])]);
    };
  }
};