import FlashBox from "./FlashBox.vue";
export default {
  name: "FlashBoxRender",
  props: {
    difficulty: {
      type: Object,
      default: () => {
        return {
          playInterval: 1
        };
      }
    },
    review: {
      type: Object,
      default: () => {
        return null;
      }
    },
    reviewMode: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => {
        return null;
      }
    }
  },
  watch: {
    data() {
      this.init();
    }
  },
  data() {
    return {
      answer: [],
      answerState: 0,
      alertVisible: false
    };
  },
  methods: {
    next() {
      this.$emit('next');
    },
    onFinish(answer) {
      this.answer = answer;
      this.$emit('answer', answer.join(','));
    },
    init() {
      this.answer = [];
      this.alertVisible = false;
    }
  },
  mounted() {
    this.init();
  }
};