import { useLoop } from '@tresjs/core';
export default {
  __name: 'ScreenshotView',
  emits: ['on-take-screenshot'],
  setup(__props, {
    expose: __expose,
    emit: __emit
  }) {
    const {
      onAfterRender
    } = useLoop();
    let needTakeScreenshot = false;
    function takeScreenshot() {
      needTakeScreenshot = true;
    }
    const emit = __emit;
    onAfterRender(({
      renderer
    }) => {
      if (needTakeScreenshot) {
        const data = renderer.domElement.toDataURL('image/png');
        emit('on-take-screenshot', data);
        needTakeScreenshot = false;
      }
    });
    __expose({
      takeScreenshot
    });
    return () => {};
  }
};