import api from "@/api";
import E808BRender from "@/components/subject/E808B/E808BRender";
import LogicalReasoningRender from "@/components/subject/LogicalReasoning/LogicalReasoningRender";
import BlockRotateRender from "@/components/subject/BlockRotate/BlockRotateRender";
import CubeComplementRender from "@/components/subject/CubeComplement/CubeComplementRender";
import FillWallRender from "@/components/subject/FillWall/FillWallRender";
import FlashDiamondRender from "@/components/subject/FlashDiamond/FlashDiamondRender";
import ImageTransformRender from "@/components/subject/ImageTransform/ImageTransformRender";
import WhackAMoleRender from "@/components/subject/WhackAMole/WhackAMoleRender";
import E808APlusRender from "@/components/subject/E808APlus/E808APlusRender";
import E808ARender from "@/components/subject/E808A/E808ARender";
import MossRender from "@/components/subject/Moss/MossRender";
import MossCopyRender from "@/components/subject/MossCopy/MossCopyRender";
import E808ERender from "@/components/subject/E808E/E808ERender";
import E808EPlusRender from "@/components/subject/E808EPlus/E808EPlusRender";
import E808DRender from "@/components/subject/E808D/E808DRender";
import E808B2Render from "@/components/subject/E808B2/E808B2Render";
import E808CRender from "@/components/subject/E808C/E808CRender";
import QuickScaleRender from "@/components/subject/QuickScale/QuickScaleRender";
import E808D2Render from "@/components/subject/E808D2/E808D2Render";
import E808B3Render from "@/components/subject/E808B3/E808B3Render";
import CodeMathRender from "@/components/subject/CodeMath/CodeMathRender";
import FastMemoryRender from "@/components/subject/FastMemory/FastMemoryRender";
import SeesawRender from "@/components/subject/Seesaw/SeesawRender";
import RandomForestRender from "@/components/subject/RandomForest/RandomForestRender.vue";
import PriceCalculateRender from "@/components/subject/PriceCalculate/PriceCalculateRender.vue";
import BlackWhiteDotRender from "@/components/subject/BlackWhiteDot/BlackWhiteDotRender.vue";
import GroundPerspectiveRender from "@/components/subject/GroundPerspective/GroundPerspectiveRender.vue";
import Unfolding3DRender from "@/components/subject/Unfolding3D/Unfolding3DRender.vue";
import FlashBoxRender from "../subject/FlashBox/FlashBoxRender.vue";
import DiceRender from "../subject/Dice/DiceRender.vue";
import NineGridRender from "../subject/NineGrid/NineGridRender.vue";
import MemoryNumbersRender from "../subject/MemoryNumbers/MemoryNumbersRender.vue";
export default {
  name: "QuestionRenderPage",
  components: {
    Unfolding3DRender,
    GroundPerspectiveRender,
    BlackWhiteDotRender,
    PriceCalculateRender,
    RandomForestRender,
    SeesawRender,
    FastMemoryRender,
    CodeMathRender,
    E808B3Render,
    E808D2Render,
    QuickScaleRender,
    E808CRender,
    E808B2Render,
    E808DRender,
    E808EPlusRender,
    E808ERender,
    E808BRender,
    MossCopyRender,
    MossRender,
    E808ARender,
    FillWallRender,
    BlockRotateRender,
    CubeComplementRender,
    E808APlusRender,
    WhackAMoleRender,
    ImageTransformRender,
    LogicalReasoningRender,
    FlashDiamondRender,
    FlashBoxRender,
    DiceRender,
    NineGridRender
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return null;
      }
    },
    subject: {
      type: String,
      default: ''
    },
    groupSize: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      finishVisible: false,
      session: null,
      answer: null
    };
  },
  methods: {
    review() {
      this.$router.replace('/review/' + this.subject + '/' + this.data.examId);
    },
    submitAndNext() {
      if (this.groupSize > 1) {
        this.batchSubmitAndNext();
      } else {
        this.singleSubmitAndNext();
      }
    },
    batchSubmitAndNext() {
      let body = {};
      Object.assign(body, this.answer);
      body.examId = this.session.examId;
      api.batchSubmitAnswer(body).then(() => {
        this.answer = null;
        this.next();
      });
    },
    singleSubmitAndNext() {
      api.submitAnswer({
        examId: this.session.examId,
        answer: this.answer,
        current: this.session.current
      }).then(() => {
        this.answer = null;
        this.next();
      });
    },
    next() {
      if (this.session.current + this.groupSize >= this.session.total) {
        this.finishVisible = true;
      } else {
        this.session.current += this.groupSize;
        this.session.list.splice(0, this.groupSize);
        this.$emit('next');
      }
    },
    onTimeUp() {
      this.finishVisible = true;
    },
    onAnswer(a) {
      this.answer = a;
      if (this.subject === 'FlashingRect' && this.session.current + this.groupSize <= this.session.total) {
        this.submitAndNext();
      }
    },
    init() {
      let cpy = {};
      Object.assign(cpy, this.data);
      this.session = cpy;
    }
  },
  mounted() {
    this.init();
    if (this.subject === 'StereoscopicSection') {
      api.submitAnswer({
        examId: this.session.examId,
        answer: '',
        current: this.session.current
      });
    }
  }
};