import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
import CSGItem from '@/components/subject/CSG/CSGItem.vue';
import DiceRender from '@/components/subject/Dice/DiceRender.vue';
import GraphicalReasoningRender from '@/components/subject/GraphicalReasoning/GraphicalReasoningRender.vue';
import NineGridRender from '@/components/subject/NineGrid/NineGridRender.vue';
import FlashBoxRender from '@/components/subject/FlashBox/FlashBoxRender.vue';
import { ref } from 'vue';
export default {
  __name: 'TestView',
  setup(__props) {
    const subject = ref('GraphicalReasoning');
    const diceData = ref({
      "questionIndex": 0,
      "question": ['eye', 'moon', 'pause'],
      "answers": [['eye', 'moon', 'quote'], ['eye', 'moon', 'pause'], ['eye', 'pause', 'moon'], ['tag', 'suitcase', 'pause'], ['eye', 'suitcase', 'pause'], ['eye', 'moon', 'quote']],
      "rightAnswer": "B,C"
    });
    const memoryNumbersData = ref({
      "questionIndex": 0,
      "question": [0, 1, 0, 3, 4, 3, 2, 1, 2, 9, 2, 9, 8],
      "rightAnswers": [1, 0, 0, 1, 0, 0, 1, 0, 1, 1, 0]
    });
    const graphicalReasoningData = ref({
      "questionIndex": 0,
      "rightAnswer": "B",
      "options": {
        "A": ["D", "E", "F"],
        "B": ["E", "F", "G"],
        "C": ["G", "H", "I"]
      },
      "questions": [{
        "transforms": [],
        "input": [null, null, null],
        "output": [null, null, null]
      }, {
        "transforms": [],
        "input": [null, null, null],
        "output": [null, null, null]
      }, {
        "transforms": [],
        "input": [null, null, null]
      }]
    });
    const flashboxData = ref({
      "questionIndex": 0,
      "question": [0],
      "rightAnswers": [0]
    });
    const runtimeParams = ref([{
      answers: ["https://sjxzc.oss-cn-beijing.aliyuncs.com/2024/10/26/3c81b535-92fa-455c-b808-e118f91ca4c5.png", 'https://sjxzc.oss-cn-beijing.aliyuncs.com/2024/10/26/4950d7d8-70d6-48cf-8acc-02ef3f50b4d6.png'],
      question: "https://sjxzc.oss-cn-beijing.aliyuncs.com/2024/10/26/3c81b535-92fa-455c-b808-e118f91ca4c5.png"
    }]);
    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");
      const _component_MemoryNumbersRender = _resolveComponent("MemoryNumbersRender");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_el_button, {
        onClick: _cache[0] || (_cache[0] = $event => subject.value = 'MemoryNumbers')
      }, {
        default: _withCtx(() => [_createTextVNode("记忆数字题")]),
        _: 1
      }), _createVNode(_component_el_button, {
        onClick: _cache[1] || (_cache[1] = $event => subject.value = 'GraphicalReasoning')
      }, {
        default: _withCtx(() => [_createTextVNode("神经网络图形推理题")]),
        _: 1
      }), subject.value == 'Dice' ? (_openBlock(), _createBlock(DiceRender, {
        key: 0,
        data: diceData.value,
        "init-runtime-params": runtimeParams.value,
        runtimeParams: runtimeParams.value[0]
      }, null, 8, ["data", "init-runtime-params", "runtimeParams"])) : _createCommentVNode("", true), subject.value == 'MemoryNumbers' ? (_openBlock(), _createBlock(_component_MemoryNumbersRender, {
        key: 1,
        data: memoryNumbersData.value,
        subject: subject.value,
        reviewMode: ""
      }, null, 8, ["data", "subject"])) : _createCommentVNode("", true), subject.value == 'GraphicalReasoning' ? (_openBlock(), _createBlock(GraphicalReasoningRender, {
        key: 2,
        data: graphicalReasoningData.value,
        subject: subject.value,
        reviewMode: "",
        editMode: ""
      }, null, 8, ["data", "subject"])) : _createCommentVNode("", true), subject.value == 'FlashBox' ? (_openBlock(), _createBlock(FlashBoxRender, {
        key: 3,
        data: flashboxData.value,
        subject: subject.value,
        reviewMode: ""
      }, null, 8, ["data", "subject"])) : _createCommentVNode("", true), subject.value == 'CSG' ? (_openBlock(), _createBlock(CSGItem, {
        key: 4,
        data: _ctx.csgData,
        subject: subject.value,
        reviewMode: ""
      }, null, 8, ["data", "subject"])) : _createCommentVNode("", true)], 64);
    };
  }
};