import "core-js/modules/es.array.push.js";
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3d8dae13"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "container"
};
const _hoisted_2 = {
  key: 1,
  class: "plus"
};
const _hoisted_3 = {
  key: 3,
  class: "box"
};
const _hoisted_4 = {
  key: 0,
  class: "box-line top"
};
const _hoisted_5 = {
  key: 1,
  class: "box-line right"
};
const _hoisted_6 = {
  key: 2,
  class: "box-line bottom"
};
const _hoisted_7 = {
  key: 3,
  class: "box-line left"
};
const _hoisted_8 = {
  key: 4,
  class: "alert"
};
import { onMounted, onUnmounted, ref, watch } from 'vue';
export default {
  __name: 'FlashBox',
  props: {
    items: {
      type: Array,
      default: () => [0, 1, 0, 2, 3, 3, 2, 1, 0, 1]
    },
    waitTime: {
      type: Number,
      default: 1000
    },
    displayTime: {
      type: Number,
      default: 100
    }
  },
  emits: ['finish'],
  setup(__props, {
    emit: __emit
  }) {
    const emit = __emit;
    const props = __props;
    const index = ref(-1);
    const status = ref(-1);
    const answers = ref([]);
    let timer;
    onUnmounted(() => {
      clearTimeout(timer);
    });
    watch(() => props.items, () => {
      clearTimeout(timer);
      status.value = -1;
      index.value = -1;
      answers.value = [];
      run();
    });
    const run = () => {
      status.value = 0;
      timer = setTimeout(() => {
        status.value = 1;
        index.value += 1;
        timer = setTimeout(() => {
          if (index.value < props.items.length - 1) {
            run();
          } else {
            status.value = 2;
          }
        }, props.displayTime);
      }, props.waitTime);
    };
    const handleClick = value => {
      if (answers.value.length < props.items.length - 1) {
        answers.value.push(value);
      } else {
        answers.value.push(value);
        status.value = 3;
        emit('finish', answers.value);
      }
    };
    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [status.value === -1 ? (_openBlock(), _createBlock(_component_el_button, {
        key: 0,
        type: "primary",
        class: "start-button",
        onClick: run
      }, {
        default: _withCtx(() => [_createTextVNode("开始")]),
        _: 1
      })) : _createCommentVNode("", true), status.value === 0 ? (_openBlock(), _createElementBlock("div", _hoisted_2)) : _createCommentVNode("", true), status.value === 2 ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 2
      }, [_createVNode(_component_el_button, {
        class: "button top-button",
        onClick: _cache[0] || (_cache[0] = $event => handleClick(0))
      }, {
        default: _withCtx(() => [_createTextVNode("上部缺口")]),
        _: 1
      }), _createVNode(_component_el_button, {
        class: "button right-button",
        onClick: _cache[1] || (_cache[1] = $event => handleClick(1))
      }, {
        default: _withCtx(() => [_createTextVNode("右部缺口")]),
        _: 1
      }), _createVNode(_component_el_button, {
        class: "button bottom-button",
        onClick: _cache[2] || (_cache[2] = $event => handleClick(2))
      }, {
        default: _withCtx(() => [_createTextVNode("下部缺口")]),
        _: 1
      }), _createVNode(_component_el_button, {
        class: "button left-button",
        onClick: _cache[3] || (_cache[3] = $event => handleClick(3))
      }, {
        default: _withCtx(() => [_createTextVNode("左部缺口")]),
        _: 1
      })], 64)) : _createCommentVNode("", true), [1, 2].includes(status.value) ? (_openBlock(), _createElementBlock("div", _hoisted_3, [!(status.value === 1 && props.items[index.value] === 0) ? (_openBlock(), _createElementBlock("div", _hoisted_4)) : _createCommentVNode("", true), !(status.value === 1 && props.items[index.value] === 1) ? (_openBlock(), _createElementBlock("div", _hoisted_5)) : _createCommentVNode("", true), !(status.value === 1 && props.items[index.value] === 2) ? (_openBlock(), _createElementBlock("div", _hoisted_6)) : _createCommentVNode("", true), !(status.value === 1 && props.items[index.value] === 3) ? (_openBlock(), _createElementBlock("div", _hoisted_7)) : _createCommentVNode("", true)])) : _createCommentVNode("", true), status.value === 3 && props.items.length > 1 ? (_openBlock(), _createElementBlock("div", _hoisted_8, " 完成 ")) : _createCommentVNode("", true)]);
    };
  }
};