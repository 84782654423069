import "core-js/modules/es.set.difference.v2.js";
import "core-js/modules/es.set.intersection.v2.js";
import "core-js/modules/es.set.is-disjoint-from.v2.js";
import "core-js/modules/es.set.is-subset-of.v2.js";
import "core-js/modules/es.set.is-superset-of.v2.js";
import "core-js/modules/es.set.symmetric-difference.v2.js";
import "core-js/modules/es.set.union.v2.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
// import AnswerButtonGroup from "@/components/train/AnswerButtonGroup";
// import CubeItem from '../Cube/CubeComponent';
// import CubeDemo from '../Cube/CubeDemo'

export default {
  name: "BlockRotateRender",
  // components: { CubeDemo },
  props: {
    review: {
      type: Object,
      default: () => {
        return null;
      }
    },
    reviewMode: {
      type: Boolean,
      default: false
    },
    runtimeParams: {
      type: Array,
      default: () => []
    },
    data: {
      type: Object,
      default: () => {
        return null;
      }
    }
  },
  watch: {
    data() {
      this.init();
    }
  },
  data() {
    return {
      answer: null,
      select: [],
      positions: [{
        x: 0,
        y: 0,
        z: 0
      }, {
        x: 0,
        y: 0,
        z: 1
      }, {
        x: 0,
        y: 0,
        z: 2
      }]
    };
  },
  methods: {
    init() {
      this.answer = null;
      this.select = [];
    },
    onChoosed(l) {
      let temp = [...this.select];
      if (temp.includes(l)) {
        temp = temp.filter(item => item !== l);
      } else {
        temp = [...temp, l];
      }
      this.select = Array.from(new Set(temp));
      const callBackData = this.select.join(',');
      this.$emit('answer', callBackData);
    }
  },
  mounted() {}
};