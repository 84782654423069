import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-5b4d6463"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "box"
};
export default {
  __name: 'NineGridColor',
  props: {
    items: {
      type: Array,
      default: () => [0, 1, 2, 3, 4, 3, 2, 1, 0]
    }
  },
  setup(__props) {
    const props = __props;
    const colors = ["#000", "#ff0", "#f00", "#0f0", "#00f"];
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.items.flat(), item => {
        return _openBlock(), _createElementBlock("div", {
          key: item,
          class: "item",
          style: _normalizeStyle({
            backgroundColor: colors[item]
          })
        }, null, 4);
      }), 128))]);
    };
  }
};