import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-11cedcf2"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "ReviewAnswerCardContainer wd100 brd2"
};
const _hoisted_2 = {
  class: "boldFont"
};
const _hoisted_3 = {
  class: "boldFont"
};
const _hoisted_4 = {
  class: "ml16"
};
const _hoisted_5 = {
  class: "ml16"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_col = _resolveComponent("el-col");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$props.groupSize == 1 ? (_openBlock(), _createBlock(_component_el_row, {
    key: 0,
    class: "bothCenter"
  }, {
    default: _withCtx(() => [_createElementVNode("span", _hoisted_2, "共" + _toDisplayString($props.questions.length) + "题", 1)]),
    _: 1
  })) : (_openBlock(), _createBlock(_component_el_row, {
    key: 1,
    class: "bothCenter"
  }, {
    default: _withCtx(() => [_createElementVNode("span", _hoisted_3, "共" + _toDisplayString($props.questions.length) + "组,每组" + _toDisplayString($props.groupSize) + "题", 1)]),
    _: 1
  })), _createVNode(_component_el_row, {
    class: "bothCenter ft14 mt8"
  }, {
    default: _withCtx(() => [_createElementVNode("span", null, "正确" + _toDisplayString($props.questions.filter(q => q == 0).length), 1), _createElementVNode("span", _hoisted_4, "错误" + _toDisplayString($props.questions.filter(q => q == 1).length), 1), _createElementVNode("span", _hoisted_5, "未答" + _toDisplayString($props.questions.filter(q => q == 2).length), 1)]),
    _: 1
  }), _createVNode(_component_el_row, {
    class: "bothCenter ft14 mt8"
  }, {
    default: _withCtx(() => [_createElementVNode("span", null, "答题耗时:" + _toDisplayString($options.secondsToMMSS($props.costTime)), 1)]),
    _: 1
  }), $props.groupSize == 1 ? (_openBlock(), _createBlock(_component_el_row, {
    key: 2,
    class: "pd12",
    gutter: 10
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.questions, (q, qi) => {
      return _openBlock(), _createBlock(_component_el_col, {
        span: 8,
        class: "leftCenter mb10",
        key: qi
      }, {
        default: _withCtx(() => [_createVNode(_component_el_button, {
          size: "small",
          onClick: $event => _ctx.$emit('goto', qi),
          class: _normalizeClass({
            'wd100 rightAnswer': q == 0,
            'wd100 wrongAnswer': q == 1,
            'wd100 missAnswer': q == 2
          })
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(qi + 1), 1)]),
          _: 2
        }, 1032, ["onClick", "class"])]),
        _: 2
      }, 1024);
    }), 128))]),
    _: 1
  })) : (_openBlock(), _createBlock(_component_el_row, {
    key: 3,
    class: "pd12",
    gutter: 10
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.questions.length / $props.groupSize, q => {
      return _openBlock(), _createBlock(_component_el_col, {
        span: 8,
        class: "leftCenter mb10",
        key: `review${q}`
      }, {
        default: _withCtx(() => [_createVNode(_component_el_button, {
          size: "small",
          onClick: $event => _ctx.$emit('goto', q - 1),
          class: "wd100"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(q), 1)]),
          _: 2
        }, 1032, ["onClick"])]),
        _: 2
      }, 1024);
    }), 128))]),
    _: 1
  })), _createVNode(_component_el_button, {
    type: "primary",
    class: "wd100",
    onClick: _cache[0] || (_cache[0] = $event => _ctx.$router.go(-1))
  }, {
    default: _withCtx(() => [_createTextVNode("结束回看")]),
    _: 1
  })]);
}