import "core-js/modules/es.array.push.js";
import ReviewAnswerCard from "@/components/train/ReviewAnswerCard";
import api from "@/api";
import QuestionReviewRenderPage from "@/components/train/QuestionReviewRenderPage";
import subjects from "@/utils/subjects";
import WatermarkerView from "@/components/WatermarkerView";
export default {
  name: "ReviewAnswerView",
  components: {
    WatermarkerView,
    ReviewAnswerCard,
    QuestionReviewRenderPage
  },
  data() {
    return {
      jumpToIndex: 0,
      answerState: [],
      subject: this.$route.params.subject,
      examId: this.$route.params.examId,
      result: null,
      costTimeInSeconds: 0
    };
  },
  methods: {
    onGoto(v) {
      this.jumpToIndex = v;
    },
    ansTypeMap(s) {
      return subjects.ansTypeMap[s];
    },
    ansModeMap() {
      return subjects.ansModeMap[this.subject];
    },
    init() {
      api.queryHistoryExamResult(this.examId).then(ret => {
        this.costTimeInSeconds = parseInt((ret.finishTimestamp - ret.startTimeStamp) / 1000);
        this.result = ret;
        for (let i = 0; i < ret.total; i++) {
          if (ret.answers && ret.answers[i] != null) {
            this.answerState.push(ret.answers[i].result ? 0 : 1);
          } else {
            this.answerState.push(2); //缺答
          }
        }
      });
    }
  },
  mounted() {
    this.init();
  }
};