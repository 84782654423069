import NineGridColor from './NineGridColor.vue';
import NineGrid from './NineGrid.vue';
export default {
  name: "NineGridRender",
  props: {
    subject: {
      type: String,
      default: 'NineGrid'
    },
    review: {
      type: Object,
      default: () => {
        return null;
      }
    },
    reviewMode: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => {
        return null;
      }
    }
  },
  methods: {
    init() {
      this.answer = null;
      this.select = [];
    },
    onChoosed(l) {
      this.select = l;
      this.$emit('answer', l);
    }
  },
  watch: {
    data() {
      this.init();
    }
  },
  data() {
    return {
      answer: null,
      select: []
    };
  }
};